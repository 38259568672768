import React from "react";
import { RiMenuLine } from "@remixicon/react";
import Avatar from "../common/Avatar";

const Header = ({ handleSidebarOpen }) => {

    return (
        <div className="sbar:hidden flex pl-4 pr-2 justify-between items-center h-14 bg-white border-b border-[#DADFE8]">
            <div><img className="block" src="/svg/kavo-logo.svg" width={102} height={24} /></div>
            <div
                className="p-2 cursor-pointer"
                onClick={handleSidebarOpen}
            >
                <RiMenuLine size={24} />
            </div>
        </div>
    );
};

export default Header;
