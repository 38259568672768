import React, {useEffect} from "react";
import Meta from "./../components/Meta";
import AuthSection from "./../components/AuthSection";
import { useRouter } from "./../util/router";
import { DEFAULT_PAGE } from "./../data/nav";

function AuthPage(props) {
    const router = useRouter();

    useEffect(() => {
        // Check if there's a referral code in the URL
        const referralCode = router.query.ref;
        if (referralCode) {
            // Store the referral code in localStorage
            localStorage.setItem('referralCode', referralCode);
        }
    }, [router.query.ref]);

    return (
        <>
            <Meta title="Auth" />
            <AuthSection
                size="md"
                bgColor="bg-white"
                bgImage=""
                bgImageOpacity={1}
                textColor=""
                type={router.query.type}
                providers={["discord"]/*["google", "facebook", "twitter"]*/}
                afterAuthPath={router.query.next || DEFAULT_PAGE}
            />
        </>
    );
}

export default AuthPage;
