import React from "react";
import Meta from "./../components/Meta";
import FaqSection from "./../components/FaqSection";
import CtaSection from "./../components/CtaSection";

function FaqPage(props) {
    return (
        <>
            <Meta title="Faq" />
            <FaqSection
                title="Frequently Asked Questions"
                subtitle=""
                strapline="We answer"
                size="md"
                bgColor="bg-white"
                bgImage=""
                bgImageOpacity={1}
                textColor=""
                showSupportButton={true}
                supportUrl="https://zendesk.com"
            />
            <CtaSection
                title={
                    <>
                        Create an account <span className="text-blue-600">today</span>!
                    </>
                }
                subtitle="Inspiring results from day one without the pain. Get your own custom dashboard and start building amazing services."
                strapline=""
                size="md"
                bgColor="bg-white"
                bgImage=""
                bgImageOpacity={1}
                textColor=""
            />
        </>
    );
}

export default FaqPage;
