import React from "react";

const StateTag = ({ state }) => {

    const STATE_TEXT_COLOR = {
        created: "#24962A",
        unfulfilled: "#ff7a00",
        denied: "#ff7a00",
        expired: "#ff7a00",
        completed: "#24962A",
    }

    const STATE_BG_COLOR = {
        created: "#EFFFEF",
        unfulfilled: "#FFF4E0",
        denied: "#EFFFEF",
        expired: "#FFF4E0",
        completed: "#EFFFEF",
    }

    return (
        <span
            className={`capitalize rounded-2xl font-medium text-xs px-3 py-1 text-[${STATE_TEXT_COLOR[state]}] bg-[${STATE_BG_COLOR[state]}]`}
        >
            {state}
        </span>
    );
};

export default StateTag;
