import React, { useState } from "react";
import Button from "./Button";
import LoadingIcon from "./LoadingIcon";
import { useAuth } from "./../util/auth";

function AuthSocial(props) {
    const auth = useAuth();
    const [pending, setPending] = useState(null);

    const providerDisplayNames = {
        google: "Google",
        facebook: "Facebook",
        twitter: "Twitter",
        github: "GitHub",
        discord: "Discord",
    };

    const onSigninWithProvider = (provider) => {
        setPending(provider);
        auth.signinWithProvider(provider)
            .then((user) => {
                // Remember this provider was last used
                // so we can indicate for next login.
                localStorage.setItem("lastUsedAuthProvider", provider);
                props.onAuth(user);
            })
            .catch((error) => {
                setPending(null);
                props.onError(error.message);
            });
    };

    const getProviderIcon = (provider) => {
        if (provider === 'discord') {
            return "/icons/discord-mark-blue.svg";
        }
        return `https://uploads.divjoy.com/icon-${provider}.svg`;
    };

    return (
        <div className="mt-8 space-y-2 w-full">
            {props.providers.map((provider) => (
                <div className="mb-2 w-full sm:w-96 mx-auto" key={provider}>
                    <Button
                        size="lg"
                        variant="simple"
                        disabled={pending === provider}
                        isBlock={true}
                        onClick={() => {
                            onSigninWithProvider(provider);
                        }}
                        startIcon={
                            pending !== provider && (
                                <img
                                    src={getProviderIcon(provider)}
                                    alt={providerDisplayNames[provider]}
                                    className="w-5 h-5"
                                />
                            )
                        }
                    >
                        {pending === provider && <LoadingIcon className="w-6" />}

                        {pending !== provider && (
                            <>
                                Continue with {providerDisplayNames[provider]}
                            </>
                        )}
                    </Button>
                </div>
            ))}
        </div>
    );
}

export default AuthSocial;
