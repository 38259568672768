import React from "react";
import { Link } from "../../util/router";

function Tabs(props) {
    const { activetab, tabItems } = props;

    return (
        <div>
            <nav className="flex text-sm border-b border-[#DADFE8] h-12 lg:h-16 gap-2 mb-4 lg:mb-6 font-medium px-4 lg:px-6 relative">
                {Object.values(tabItems).map((tab, index) => (
                    <Link
                        to={`${tab.slug}`}
                        className={
                            "flex items-center justify-center px-2" +
                            (Object.keys(tabItems)[index] === activetab
                                ? " text-link border-b-2 border-link pt-px -mb-px"
                                : "") +
                            (Object.keys(tabItems)[index] !== activetab ? " text-darkgrey" : "")
                        }
                        key={tab.slug}
                    >
                        {tab.label}
                        {tab.count > 0 && (
                            <span className="flex h-5 min-w-[20px] px-1.5 ml-1.5 bg-[#E7EFFF] text-link font-normal rounded-2xl items-center justify-center text-xs">
                                {tab.count}
                            </span>
                        )}
                    </Link>
                ))}
                {props.children && <div className="flex h-full items-center ml-auto">
                    {props.children}
                </div>}
            </nav>
        </div>
    );
}

export default Tabs;
