import React from "react";
import Section from "./Section";
import LoadingIcon from "./LoadingIcon";

function PageLoader(props) {
    return (
        <Section size="lg" bgColor="#F7F9FC">
            {!props.children && <LoadingIcon className="mx-auto w-7" />}

            {props.children && <>{props.children}</>}
        </Section>
    );
}

export default PageLoader;
