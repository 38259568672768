import React, { useState, useEffect } from "react";
import Meta from "./../components/Meta";
import { requireAuth } from "../util/auth";
import PageTitle from "../components/common/PageTitle";
import SettingsPersonal from "./settingsPersonal";
import SettingsBilling from "./settingsBilling";
import Tabs from "../components/common/Tabs";
import { useRouter } from "./../util/router";
import { useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useQuery = () => {
    const location = useLocation();
    return React.useMemo(() => new URLSearchParams(location.search), [location.search]);
};

function SettingsPage(props) {
    const query = useQuery();

    useEffect(() => {
        const discordLink = query.get('discord-link');
        if (discordLink === 'success') {
            toast.success("Discord account linked successfully!");
        } else if (discordLink === 'error') {
            toast.error("Failed to link Discord account.");
        }
    }, [query]);

    const router = useRouter();
    const [formAlert, setFormAlert] = useState(null);

    // State to control whether we show a re-authentication flow
    // Required by some security sensitive actions, such as changing password.
    const [reauthState, setReauthState] = useState({
        show: false,
    });

    const TAB_ITEMS = {
        PERSONAL: { slug: "/settings/my-details", label: "My details" },
        BILLING: { slug: "/settings/billing", label: "Billing" },
    };

    const querySection = router.query.section.toUpperCase();
    const activeTab = TAB_ITEMS[querySection] ? querySection : "PERSONAL";

    const handleStatus = ({ type, message, callback }) => {
        if (type === "requires-recent-login") {
            // First clear any existing message
            setFormAlert(null);
            // Then update state to show re-authentication modal
            setReauthState({
                show: true,
                // Failed action to try again after reauth
                callback: callback,
            });
        } else {
            // Display message to user (type is success or error)
            setFormAlert({
                type: type,
                message: message,
            });

            // Use react-toastify to show the message
            if (type === 'success') {
                toast.success(message);
            } else if (type === 'error') {
                toast.error(message);
            }
        }
    };

    return (
        <>
            <Meta title="Settings - Kavo" />
            <PageTitle title="Settings" />

            <Tabs activetab={activeTab} tabItems={TAB_ITEMS} />

            <section>
                {activeTab === 'PERSONAL' && <SettingsPersonal onStatus={handleStatus} />}
                {activeTab === 'BILLING' && <SettingsBilling />}
            </section>
        </>
    );
}

export default requireAuth(SettingsPage);