import React from "react";
import { forwardRef } from 'react'

function TextField(props, ref) {
    const { error, type = "text", size = "md", label, className, inputClassName, ...inputProps } = props;

    const classes = {
        base: "bg-[#FCFCFD] block border placeholder-gray-400 w-full rounded-lg focus:z-10 border-[#DADFE8] focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50",
        size: {
            sm: "py-2 leading-5",
            md: "h-11 px-4 py-3 leading-6",
            lg: "",
        },
    };

    return (
        <div className={"w-full" + (className ? ` ${className}` : "")}>
            {label && (
                <label className="block mb-1 font-medium" htmlFor={props.id}>
                    {label}
                </label>
            )}

            {type === "textarea" && (
                <textarea
                    className={`${classes.base} ${classes.size[size]}`}
                    ref={ref}
                    {...inputProps}
                />
            )}

            {type !== "textarea" && (
                <input
                    className={`${classes.base} ${classes.size[size]} ${inputClassName}`}
                    ref={ref}
                    type={type}
                    {...inputProps}
                />
            )}

            {error && <p className="text-sm text-left text-red-600 mt-1">{error.message}</p>}
        </div>
    );
}

export default forwardRef(TextField);
