import { RiArrowLeftLine, RiArrowRightLine } from '@remixicon/react';
import React from 'react';

const Pagination = ({ pagination, onPageChange }) => {
    if (! pagination || pagination.total === 0) {
        return null;
    }

    const { page, limit, total, pages } = pagination;

    const hasMore = page < pages;

    const buttonStyle = "w-12 h-12 flex items-center justify-center rounded-lg bg-white border border-[#DADFE8] disabled:opacity-50 focus:outline-none focus:ring hover:bg-grey transition-colors";

    return (
        pages > 1 && (
            <div className="mt-6 mb-4 flex items-center justify-center">
                <button
                    onClick={() => onPageChange(page - 1)}
                    disabled={page === 1 ? true : false}
                    className={buttonStyle}
                    type="button"
                >
                    <RiArrowLeftLine size={20} />
                </button>
                <span className="mx-4 text-sm">
                    {page} of {pages}
                </span>
                <button
                    onClick={() => onPageChange(page + 1)}
                    disabled={!hasMore}
                    className={buttonStyle}
                >
                    <RiArrowRightLine size={20} />
                </button>
            </div>
        )
    );
};

export default Pagination;