import React from "react";

const ContentBox = ({ children, classes, ...otherProps}) => {

    return (
        <div className={`mx-2 lg:mx-6 px-4 py-3 bg-white rounded-2xl ${classes}`} {...otherProps}>
            { children }
        </div>
    );
};

export default ContentBox;
