import React from "react";
import { RiShoppingBag2Line } from "@remixicon/react";
import { RiBarChartBoxLine } from "@remixicon/react";
import { RiCustomerService2Line } from "@remixicon/react";
import { RiSettings4Line } from "@remixicon/react";
import { RiDiscordFill } from "@remixicon/react";
import { RiListCheck2 } from "@remixicon/react";

const PageIcon = ({ icon }) => {
    return (
        <div className="flex items-center justify-center mr-1 w-9 h-full">
            { icon === 'RiShoppingBag2Line' && <RiShoppingBag2Line size={20} /> }
            { icon === 'RiBarChartBoxLine' && <RiBarChartBoxLine size={20} /> }
            { icon === 'RiCustomerService2Line' && <RiCustomerService2Line size={20} /> }
            { icon === 'RiSettings4Line' && <RiSettings4Line size={20} /> }
            { icon === 'RiDiscordFill' && <RiDiscordFill size={20} /> }
            { icon === 'RiListCheck2' && <RiListCheck2 size={20} /> }
        </div>
    );
};

export default PageIcon;
