import React from "react";
import ReactFlagsSelect from "react-flags-select";

function CountrySelector({ error, field }) {

    return (
        <div className="w-full">
            <ReactFlagsSelect
                id="country"
                className="w-full bg-[#FCFCFD] block border placeholder-gray-400 w-full rounded-lg focus:z-10 border-[#DADFE8] focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50 h-11 leading-6"
                countries={["AT", "DE", "PL"]}
                customLabels={{
                    "AT": { primary: "Austria" },
                    "DE": { primary: "Germany" },
                    "PL": { primary: "Poland" },
                }}
                selected={field.value}
                onSelect={(code) => { field.onChange(code) }}
            />
            {error && <p className="text-sm text-left text-red-600 mt-1">Choose your country</p>}
        </div>
    );
};

export default CountrySelector;
