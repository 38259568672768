import React, { useEffect, useState } from "react";
import Meta from "./../components/Meta";
import { requireAuth } from "../util/auth";
import PageTitle from "../components/common/PageTitle";
import { useRouter } from "../util/router";
import {fetchPurchaseDetails, fetchSaleDetails} from "../util/api";
import LoadingIcon from "../components/LoadingIcon";
import DealDetailHeader from "../components/common/DealDetailHeader";
import DealItemsTable from "./dealItemsTable";
import {formatDate} from "../util/formatter";

function DealDetailPage({ title, dealType }) {
    const router = useRouter();
    const id = router.query.section;

    useEffect(() => {
        getDealData(id);
    }, [router.query.section])

    const [dealData, setDealData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const getDealData = async (tab) => {
        try {
            setIsLoading(true);
            if (dealType === 'WTB') {
                const response = await fetchPurchaseDetails(id);

                setDealData(response.data);
            } else {
                const response = await fetchSaleDetails(id);

                setDealData(response.data);
            }
        } catch (err) {
            setError(err.message || "An error occurred while fetching listings.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Meta title={`${title} - Kavo`} />
            <PageTitle title={title} />
            <DealDetailHeader
                dealType={dealType}
                id={dealData?.unique_id}
                date={dealData?.created_at ? formatDate(dealData?.created_at) : ''}
                // sum up quantity of deal_items
                quantity={dealData?.deal_items.reduce((acc, item) => acc + item.quantity, 0)}
                state={dealData?.state}
            />

            {! isLoading && <DealItemsTable data={dealData} dealType={dealType} />}
            {isLoading && <div className="mt-16 text-center">
                <LoadingIcon className="inline-block w-6" />
            </div>}
        </>
    );
}

export default requireAuth(DealDetailPage);
