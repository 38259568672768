export const NAV_ITEMS = [
    {
        name: "Purchases",
        slug: "/purchases",
        defaultKey: "current",
        icon: "RiShoppingBag2Line",
    },
    {
        name: "Sales",
        slug: "/sales",
        defaultKey: "current",
        icon: "RiBarChartBoxLine",
    },
    {
        name: "Listings",
        slug: "/listings",
        defaultKey: "buy",
        icon: "RiListCheck2",
    },
    {
        name: "Settings",
        slug: "/settings",
        defaultKey: "my-details",
        icon: "RiSettings4Line",
    },
    {
        name: "Customer support",
        slug: "mailto:contact@kavo.app?subject=Kavo support",
        external: true,
        icon: "RiCustomerService2Line",
    },
    {
        name: "Discord server",
        slug: process.env.REACT_APP_DISCORD_INVITE_LINK ?? "https://discord.com",
        external: true,
        icon: "RiDiscordFill",
    },
];

export const DEFAULT_PAGE = `${NAV_ITEMS[0].slug}/${NAV_ITEMS[0].defaultKey}`;
