import React from "react";

export const MAX_INVITES = 6;

const InviteProgressBar = ({ invitedCount }) => {
    // Calculate the width percentage
    const widthPercentage = Math.min((invitedCount / MAX_INVITES) * 100, 100);

    return (
        <div className="mt-1 h-2.5 rounded-lg bg-white p-0.5">
            <div
                className="h-full bg-gradient-to-r from-[#003499] to-link rounded-lg transition-all duration-300 ease-in-out"
                style={{ width: `${widthPercentage}%` }}
            ></div>
        </div>
    );
};

export default InviteProgressBar;