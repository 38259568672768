import React, { useState, useEffect } from "react";
import ClickAwayListener from 'react-click-away-listener';
import { RiCloseLine } from "@remixicon/react";
import { RiLogoutBoxRLine } from "@remixicon/react";
import { RiExternalLinkLine } from "@remixicon/react";
import { RiArrowRightLine } from "@remixicon/react";
import { NAV_ITEMS } from "../../data/nav";
import { Link, useRouter } from "./../../util/router";
import { useAuth } from "./../../util/auth";
import PageIcon from "./PageIcon";
import { toast } from 'react-toastify';
import Button from "../Button";
import InviteProgressBar, {MAX_INVITES} from "../common/InviteProgressBar";

const Sidebar = ({ isOpen, handleSidebarClose, subscribedCount }) => {
    const router = useRouter();
    const auth = useAuth();
    const [isVisible, setIsVisible] = useState(isOpen);
    const transitionDuration = 300;
    let timer = null;

    const handleCloseClick = () => {
        if (isVisible) {
            setIsVisible(false);
            clearTimeout(timer);
            timer = setTimeout(handleSidebarClose, transitionDuration);
        }
    };

    const handleReferralCopy = (e) => {
        e.preventDefault();
        let link = window.location.origin + '/auth/signup?ref=' + auth.user.referral_code;
        navigator.clipboard.writeText(link);
        toast.success('Your invite link was copied successfully!');
    };

    useEffect(() => {
        if (isOpen && !isVisible) {
            setIsVisible(true);
        }
    }, [isOpen]);

    useEffect(() => {
        return () => {
            clearTimeout(timer);
        }
    }, []);

    return (
        <div
            className={`fixed top-0 left-0 w-screen h-full sbar:h-full sbar:block z-40 sbar:w-64 shrink-0 transition-colors ease-in-out duration-${transitionDuration} ${isVisible ? 'bg-black/50' : 'bg-black/0'} ${isOpen ? 'block' : 'hidden'}`}
        >
            <ClickAwayListener onClickAway={handleCloseClick}>
                <div className={`h-full overflow-y-auto bg-white min-h-full sbar:min-h-screen border-r border-[#DADFE8] flex flex-col w-full max-w-[256px] shrink-0 absolute sbar:static transition-all ease-in-out duration-${transitionDuration} top-0 ${isVisible ? 'right-0' : '-right-64'}`}>

                    <div className="flex h-14 px-4 items-center shrink-0">
                        <img
                            className="hidden sbar:block"
                            src="/svg/kavo-logo.svg"
                            width={102}
                            height={24}
                        />
                        <div className="font-semibold text-base sbar:hidden">
                            Menu
                        </div>
                        <div
                            className="w-8 h-8 ml-auto flex items-center justify-center text-gray-800 cursor-pointer sbar:hidden"
                            onClick={handleCloseClick}
                        >
                            <RiCloseLine size={26} />
                        </div>
                    </div>

                    <ul className="mt-2 lg:mt-4 mb-4 mx-1.5 flex flex-col h-full text-sm text-darkgrey gap-y-4">
                        {NAV_ITEMS.map((item) => (
                            <li key={item.name} className={`h-7 lg:h-9 ${item.classes ? item.classes : ''}`}>
                                {item.external ? (
                                    <a
                                        href={item.slug}
                                        onClick={handleCloseClick}
                                        className={`flex items-center h-full font-medium pr-2.5 transition-colors hover:text-[#001C2C] ${item.classes}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <PageIcon icon={item.icon} />
                                        {item.name}
                                        <RiExternalLinkLine size={12} className="ml-1.5" />
                                    </a>
                                ) : (
                                    <Link
                                        to={`${item.slug}${item.defaultKey ? `/${item.defaultKey}` : ""}`}
                                        onClick={handleCloseClick}
                                        className={`flex items-center h-full font-medium pr-2.5
                                            ${router.pathname.startsWith(item.slug) ? "bg-grey text-[#001C2C] rounded-lg" : "transition-colors hover:text-[#001C2C]"}
                                            ${item.classes}`}
                                        target={item.newTab ? "_blank" : "_self"}
                                    >
                                        <PageIcon icon={item.icon} />
                                        {item.name}
                                    </Link>
                                )}
                            </li>
                        ))}
                    </ul>

                    <div className="px-4 pb-4 pt-5 mx-2 mt-auto mb-2 bg-gradient-to-b from-[#F4F8FF] to-[#E7EFFF] font-medium rounded-2xl">
                        <div className="tracking-tighter">
                            Get up to {MAX_INVITES} free extra months!
                        </div>
                        <div className="font-normal text-darkgrey text-xs tracking-tight mb-5 mt-1.5">
                            Refer friends and receive additional subscription months for free.
                        </div>
                        <div className="flex">
                            <span className="text-sm">Refer plan</span>
                            <span className="text-xs ml-auto text-black font-normal">{subscribedCount}/{MAX_INVITES} people</span>
                        </div>
                        <InviteProgressBar invitedCount={subscribedCount} />
                        <Button
                            className="h-8 w-full tracking-tight text-xs mt-4"
                            type="button"
                            onClick={handleReferralCopy}
                        >
                            Copy invite link <RiArrowRightLine size={16} className="ml-2 inline-block align-text-top" />
                        </Button>
                    </div>

                    <div
                        className="px-6 py-5 max-w-full border-t border-[#DADFE8] cursor-pointer flex items-center text-darkgrey text-sm"
                        onClick={auth.signout}
                    >
                        <RiLogoutBoxRLine size={20} className="mr-3" />
                        Logout
                    </div>
                </div>
            </ClickAwayListener>
        </div>
    );
};

export default Sidebar;
