import React, { useState } from "react";
import { RiMailLine } from "@remixicon/react";
import { requireAuth } from "./../util/auth";
import { Controller, useForm } from "react-hook-form";
import TextField from "./../components/TextField";
import Button from "./../components/Button";
import LoadingIcon from "./../components/LoadingIcon";
import { useAuth } from "./../util/auth";
import {updateProfile} from "../util/api";
import ContentBox from "../components/common/ContentBox";
import DiscordAccountSection from "../components/settings/DiscordAccountSection";
import CountrySelector from "../components/common/CountrySelector";


function SettingsPersonalPage(props) {
    const auth = useAuth();
    const address = auth.user.addresses;
    const [pending, setPending] = useState(false);
    const { control, register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        // Show pending indicator
        setPending(true);

        return updateProfile(data)
            .then(() => {
                // Set success status
                props.onStatus({
                    type: "success",
                    message: "Your profile has been updated",
                });
                // Update local auth user data
                auth.user = { ...auth.user, ...data };
            })
            .catch((error) => {
                // Set error status
                props.onStatus({
                    type: "error",
                    message: error.message || "An error occurred while updating your profile",
                });
            })
            .finally(() => {
                // Hide pending indicator
                setPending(false);
            });
    };

    return (
        <ContentBox classes="max-w-6xl">
            <div className="py-0.5 lg:p-2.5">
                <h2 className="text-xl text-[#101F2F] font-semibold mt-1 mb-1">Personal info</h2>
                <p className="text-sm text-darkgrey">Update your personal details here</p>

                <form className="text-sm" onSubmit={handleSubmit(onSubmit)}>

                    <fieldset className="mt-6 py-6 border-y border-[#E4E7EC]">
                        <div className="flex flex-col lg:flex-row w-full gap-4 justify-between">
                            <div className="w-80">
                                <label className="block text-[#101F2F] text-sm font-medium">Name</label>
                            </div>
                            <div className="flex flex-col lg:flex-row gap-3 lg:gap-5 w-full lg:max-w-[60%]">
                                <TextField
                                    type="text"
                                    id="first_name"
                                    name="first_name"
                                    placeholder="First name"
                                    defaultValue={auth.user.first_name}
                                    error={errors.first_name}
                                    {...register('first_name', {
                                        required: "Please enter your first name",
                                    })}
                                />
                                <TextField
                                    type="text"
                                    id="last_name"
                                    name="last_name"
                                    placeholder="Last name"
                                    defaultValue={auth.user.last_name}
                                    error={errors.last_name}
                                    {...register('last_name', {
                                        required: "Please enter your last name",
                                    })}
                                />
                            </div>
                        </div>
                    </fieldset>

                    <fieldset className="py-6 border-b border-[#E4E7EC]">
                        <div className="flex flex-col lg:flex-row w-full gap-4 justify-between">
                            <div className="w-80">
                                <label className="block text-[#101F2F] text-sm font-medium">Email address</label>
                            </div>
                            <div className="w-full lg:max-w-[60%] relative">
                                <RiMailLine size="20" className="absolute top-3 left-4 text-darkgrey" />
                                <TextField
                                    disabled
                                    readonly
                                    type="text"
                                    id="email"
                                    name="email"
                                    placeholder="Email"
                                    defaultValue={auth.user.email}
                                    error={errors.email}
                                    inputClassName="pl-11"
                                    {...register('email', {
                                            required: "Please enter your e-mail",
                                            pattern: {
                                            value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            message: "Invalid email address",
                                        },
                                    })}
                                />
                            </div>
                        </div>
                    </fieldset>

                    <fieldset className="mb-6 py-6 border-b border-[#E4E7EC]">
                        <div className="flex flex-col lg:flex-row w-full gap-4 justify-between">
                            <div className="w-80 text-sm">
                                <label className="block text-[#101F2F] font-medium">Address</label>
                                <p className="mt-1 text-darkgrey">For receiving and dispatching goods</p>
                            </div>
                            <div className="w-full lg:max-w-[60%]">

                                <div className="flex flex-col lg:flex-row gap-3 lg:gap-5">
                                    <Controller
                                        id="country"
                                        control={control}
                                        name="country"
                                        defaultValue={address?.country}
                                        render={({ field }) => <CountrySelector field={field} error={errors.country} />}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    <TextField
                                        type="text"
                                        id="street"
                                        name="street"
                                        placeholder="Street name"
                                        defaultValue={address?.street}
                                        error={errors.street}
                                        {...register('street', {
                                            required: "Please enter your street name",
                                        })}
                                    />
                                </div>

                                <div className="flex flex-col lg:flex-row gap-3 lg:gap-5 mt-3">
                                    <TextField
                                        type="text"
                                        id="street_number"
                                        name="street_number"
                                        placeholder="House number"
                                        defaultValue={address?.street_number}
                                        error={errors.street_number}
                                        {...register('street_number', {
                                            required: "Please enter your house number",
                                        })}
                                    />
                                    <TextField
                                        type="text"
                                        id="postal_code"
                                        name="postal_code"
                                        placeholder="Postal code"
                                        defaultValue={address?.postal_code}
                                        error={errors.postal_code}
                                        {...register('postal_code', {
                                            required: "Please enter your postal code",
                                        })}
                                    />
                                </div>

                                <TextField
                                    className="mt-3"
                                    type="text"
                                    id="city"
                                    name="city"
                                    placeholder="City"
                                    defaultValue={address?.city}
                                    error={errors.city}
                                    {...register('city', {
                                        required: "Please enter your city",
                                    })}
                                />
                            </div>
                        </div>
                    </fieldset>

                    <div className="text-right mb-6">
                        <Button type="submit" size="xl" variant="primary" disabled={pending} className="w-full md:w-auto">
                            {!pending && <>Save</>}

                            {pending && <LoadingIcon className="w-6"/>}
                        </Button>
                    </div>

                </form>

                <h2 className="text-xl text-[#101F2F] font-semibold mb-1 mt-10">Server</h2>
                <p className="text-sm text-darkgrey">Connect your Kavo account with Discord</p>

                <form>
                    <fieldset className="mt-6 mb-10 py-6 border-y border-[#E4E7EC]">
                        <div className="flex flex-col lg:flex-row w-full gap-4 justify-between">
                            <div className="w-80">
                                <label className="block text-[#101F2F] text-sm font-medium">Discord</label>
                            </div>
                            <div className="flex flex-col lg:flex-row gap-3 lg:gap-5 w-full lg:max-w-[60%]">
                                <DiscordAccountSection />
                            </div>
                        </div>
                    </fieldset>
                </form>

            </div>
        </ContentBox>
    );
}

export default requireAuth(SettingsPersonalPage);
