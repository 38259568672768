import React from "react";
import { RiDiscordFill } from "@remixicon/react";
import {useAuth} from "../../util/auth";
import TextField from "../TextField";
import Button from "../Button";

function DiscordAccountSection() {
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:3000/';
    const auth = useAuth();
    console.log("DiscountAccountSection user", auth);
    const discordUser = auth?.user.discord_users;
    console.log("DiscountAccountSection discordUser", discordUser);

    return (
        <>
            <TextField
                type="text"
                id="discord_link"
                name="discord_link"
                placeholder="Discord username"
                defaultValue={discordUser?.username}
                readOnly
                inputClassName="cursor-default"
                onClick={() => document.getElementById('discordConnectBtn').click()}
            />
            <div className="text-right">
                <Button
                    id="discordConnectBtn"
                    size="lg"
                    variant="primary"
                    href={`${BACKEND_URL}passport/discord?userId=${auth?.user.id}`}
                    target="_blank"
                    className="w-full md:w-auto"
                >
                    <RiDiscordFill size={20} className="inline-block mr-0.5 -mt-px align-text-top" /> Connect Discord
                </Button>
            </div>
        </>
    );
}

export default DiscordAccountSection;