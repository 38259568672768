import React from "react";

const ProductThumbnail = ({ imageUrl }) => {

    return (
        <div className="mr-2.5 h-12 w-12 bg-grey rounded-lg shrink-0 inline-block align-middle flex justify-center flex-col">
            <img src={imageUrl} width="100%" height="auto" className="block" />
        </div>
    );
};

export default ProductThumbnail;
