import React from "react";

const PageTitle = ({ title }) => {
    return (
        <>
            <h1 className="px-4 lg:px-6 pt-3 lg:pt-4 pb-3 text-base lg:text-2xl text-[#101F2F] font-semibold">
                {title}
            </h1>
        </>
    );
};

export default PageTitle;
