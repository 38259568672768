import React from "react";
import TextField from "../TextField";
import { RiCloseLine, RiSearchLine } from "@remixicon/react";
import ClickAwayListener from "react-click-away-listener";

const SearchFilter = ({ handleSearchModalClose, searchTerm, handleSearch, showSearchModal, setShowSearchModal }) => {
    return (
        <>
            <label className="relative hidden lg:block lg:w-96">
                    <TextField
                        type="text"
                        name="search_term"
                        placeholder="Search..."
                        defaultValue={searchTerm}
                        onChange={handleSearch}
                        className="font-normal"
                        inputClassName="pl-8 h-9 py-0 text-sm font-normal placeholder-darkgrey"
                    />
                    <RiSearchLine
                        size={16}
                        className="absolute text-darkgrey top-2.5 left-2.5"
                    />
                </label>
                <span className="relative lg:hidden">
                    <span
                        className="p-1 cursor-pointer block"
                        onClick={() => setShowSearchModal(true)}
                    >
                        <RiSearchLine size={20} className="text-darkgrey" />
                    </span>
                    {showSearchModal && (
                        <ClickAwayListener onClickAway={handleSearchModalClose}>
                            <div className="bg-white absolute rounded-xl w-[262px] right-0 top-auto shadow-lg py-3.5">
                                <div className="w-[242px] mx-auto">
                                    <div className="mb-2.5 flex items-center">
                                        Search
                                        <div
                                            className="w-7 h-7 ml-auto flex items-center justify-center text-gray-800 cursor-pointer"
                                            onClick={handleSearchModalClose}
                                        >
                                            <RiCloseLine size={22} />
                                        </div>
                                    </div>
                                    <label className="relative block">
                                        <TextField
                                            type="text"
                                            name="search_term"
                                            placeholder="Search..."
                                            defaultValue={searchTerm}
                                            onChange={handleSearch}
                                            className="font-normal"
                                            inputClassName="pl-8"
                                        />
                                        <RiSearchLine
                                            size={19}
                                            className="absolute text-darkgrey top-3 left-2"
                                        />
                                    </label>
                                </div>
                            </div>
                        </ClickAwayListener>
                    )}
                </span>
        </>
    );
};

export default SearchFilter;
