import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL + 'api' : 'http://localhost:3000/api';

const api = axios.create({
    baseURL: API_BASE_URL,
});

// Function to set the auth token for all future requests
export const setAuthToken = (token) => {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

/** Pagination generics **/
// TODO: increase
export const PER_PAGE = 50;

const fetchPaginatedData = async (endpoint, params = {}) => {
    try {
        const response = await api.get(endpoint, { params });
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error;
    }
};

/** API functions **/
export const updateProfile = async (data) => {
    try {
        const response = await api.put('/profile', data);
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error;
    }
};

export const fetchBuyListings = async (params = {}) => {
    return fetchPaginatedData('/listings?type=buy', params);
};
export const fetchSellListings = async (params = {}) => {
    return fetchPaginatedData('/listings?type=sell', params);
};
export const deleteListing = async (listingId) => {
    try {
        const response = await api.delete('/listings/' + listingId);
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error;
    }
}

export const fetchReferrals = async () => {
    try {
        const response = await api.get('/profile/referrals');
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error;
    }
};

export const fetchPurchases = async (params = {}) => {
    return fetchPaginatedData('/deals/purchases', {
        ...params
    });
};

export const fetchHistoryPurchases = async (params = {}) => {
    return fetchPaginatedData('/deals/purchases/history', {
        ...params
    });
};

export const fetchSales = async (params = {}) => {
    return fetchPaginatedData('/deals/sales', {
        ...params
    });
};

export const fetchHistorySales = async (params = {}) => {
    return fetchPaginatedData('/deals/sales/history', {
        ...params
    });
};

export const fetchSaleDetails = async (dealId) => {
    try {
        const response = await api.get('/deals/sale/' + dealId);
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error;
    }
};

export const fetchPurchaseDetails = async (dealId) => {
    try {
        const response = await api.get('/deals/purchase/' + dealId);
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error;
    }
};


export default api;