import React, {useState} from "react";
import { RiUserLine } from "@remixicon/react";
import { RiMapPinLine } from "@remixicon/react";
import { RiDiscordFill } from "@remixicon/react";
import { RiArrowUpSLine } from "@remixicon/react";
import ContentBox from "./ContentBox";
import DiscordUserLink from "./DiscordUserLink";

const PartnerInfoBox = ({ partnerData, partnerType }) => {

    const [isOpen, setIsOpen] = useState(false);

    const handleBuyerToggle = () => {
        const targetElement = document.getElementById('partnerInfoBox');

        if (window.innerWidth >= 1024) {
            return;
        }

        if (isOpen) {
            targetElement.style.height = 0;
            setIsOpen(false);
        } else {
            targetElement.style.height = `${targetElement.scrollHeight}px`;
            setIsOpen(true);
        }
    };

    return (
        <ContentBox classes="h-auto lg:ml-2.5 lg:w-72 xl:w-[372px] mb-3 lg:mb-0 shrink-0">
            <div className="px-1">
                <div
                    className="flex items-center h-10 font-semibold cursor-pointer lg:cursor-default"
                    onClick={handleBuyerToggle}
                >
                    <RiUserLine size={16} className="mr-2" /> {partnerType}
                    <span className={`px-2 py-1 ml-auto mt-0.5 lg:hidden transfom transition duration-200 ease-in-out ${isOpen ? 'rotate-180' : ''}`}>
                        <RiArrowUpSLine size={27} />
                    </span>
                </div>
                <div
                    className={`${isOpen ? '' : ''} h-0 lg:h-auto overflow-hidden transition-all duration-300 ease-in-out`}
                    id="partnerInfoBox"
                >
                    <div className="mt-2 min-h-14 bg-grey rounded-lg px-2.5 py-1.5 flex items-center">
                        {/* <Avatar
                            size={10}
                            classes="mr-4 xl:mr-8"
                            imageUrl={'/images/avatar.png'}
                        /> */}
                        <div className="text-sm font-medium">
                            <div className="text-xs text-darkgrey">
                                <DiscordUserLink userId={partnerData.discord_user.discord_id}>
                                    @{partnerData.discord_user.username}
                                </DiscordUserLink>
                            </div>
                        </div>
                        <a
                            href={`https://discord.com/users/${partnerData.discord_user.discord_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="h-8 bg-white rounded-lg flex justify-center items-center text-link ml-auto text-xs px-2.5 shrink-0"
                        >
                            View<span className="hidden xl:inline ml-0.5">profile</span>
                        </a>
                    </div>
                    { partnerData.addresses && <>
                        <div className="flex items-center mt-1.5 py-3.5 font-semibold">
                            <RiMapPinLine size={16} className="mr-2" /> Address
                        </div>
                        <div className="text-sm leading-relaxed font-medium">
                            <p className="font-bold">
                                {partnerData.first_name} {partnerData.last_name}<br/>
                            </p>
                            {partnerData.addresses[0]?.postal_code} {partnerData.addresses[0]?.city}<br/>
                            {partnerData.addresses[0]?.street} {partnerData.addresses[0]?.street_number}<br/>
                            {partnerData.addresses[0]?.country}
                        </div>
                    </> }

                    <DiscordUserLink
                        userId={partnerData.discord_id}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mt-8 mb-3 w-full h-11 bg-link rounded-lg flex justify-center items-center text-white text-sm px-2.5"
                    >
                        <RiDiscordFill color="#fff" size={20} className="mr-1.5" /> Send message on Discord
                    </DiscordUserLink>
                </div>
            </div>
        </ContentBox>
    );
};

export default PartnerInfoBox;
