import React from "react";

const AuthLayout = ({ children }) => {
    return (
        <div className="w-full min-h-screen flex flex-col lg:flex-row-reverse bg-white">
            <div className="flex w-full lg:w-3/5 items-center justify-center">
                {children}
            </div>
            <div className="mt-auto w-full lg:mt-0 flex w-full lg:w-2/5 items-center justify-center flex-col bg-[#1D54C0] bg-[url('/public/images/noise.png')]">
                <div className="flex w-full justify-between flex-col h-full">
                    <img
                        className="hidden lg:block m-12"
                        src="/svg/kavo-logo-white.svg"
                        width={203}
                        height={48}
                    />
                    <img
                        className="w-4/5 hidden lg:block mx-auto mt-4"
                        src="/images/mac.png"
                    />
                    <div className="mx-3 my-10 lg:m-10 pb-6 text-white sm:text-center lg:text-left">
                        <h1 className="text-3xl lg:text-5xl mb-4 lg:mb-3 font-bold tracking-tight lg:leading-snug">
                            Streamline
                            <br />
                            your selling process
                        </h1>
                        <p className="text-sm font-normal lg:text-xl tracking-tight">
                            The one-stop platform that links you to all selling channels.
                        </p>
                        <div className="lg:hidden mt-10 text-center text-sm text-white font-normal">
                            &copy; 2022 Kavo. All rights reserved.
                            <div className="font-semibold mt-1">
                                <a href="#terms" className="text-white underline hover:text-[#ccc]">
                                    Terms & Conditions
                                </a>
                                <a href="#privacy" className="text-white ml-3 underline hover:text-[#ccc]">
                                    Privacy Policy
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default AuthLayout;
